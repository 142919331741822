import * as React from 'react';
import { WpImageFixed } from 'shared/types/wp_localImage';
import { Feature, FeatureProps } from './feature';
import styles from './feature.module.css';
import { Picture } from 'ui/components/atoms/picture/picture';

export const FeatureImage = ({
  image,
  ...props
}: FeatureProps & {
  image?: WpImageFixed;
}) => {
  const imgSrc = image ? image.localFile : undefined;
  return (
    <Feature {...props}>
      <Picture
        type="fixed"
        className={styles.picture}
        placeholder="86x86"
        fixed={imgSrc?.childImageSharp.fixed}
      />
    </Feature>
  );
};
