import { Link } from 'gatsby';
import * as React from 'react';
import styles from './breadcrumbs.module.css';
import { decodeEntities } from 'util/decodeEntities';

export type BreadcrumbsProps = {
  breadcrumbs: {
    text: string;
    url: string;
  }[];
};

export const Breadcrumbs = ({ breadcrumbs }: BreadcrumbsProps) => {
  return (
    <div className={styles.container}>
      {breadcrumbs.map((x, i) => (
        <React.Fragment key={i}>
          {i !== 0 && <span className={styles.divider}>/</span>}
          <Link className={styles.link} to={x.url}>
            {decodeEntities(x.text)}
          </Link>
        </React.Fragment>
      ))}
    </div>
  );
};
