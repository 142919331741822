import { Link } from 'gatsby';
import * as React from 'react';
import { TextSub1 } from 'ui/components/atoms/typography/typography';
import styles from './alt_link.module.css';

type AltLinkProps = {
  label: string;
  href: string;
};

export const AltLink = ({ label, href }: AltLinkProps) => (
  <Link to={href} className={styles.link}>
    <TextSub1 tagName="div">{label}</TextSub1>
  </Link>
);
