import * as React from 'react';
import { Container } from 'ui/components/organisms/container/container';
import { TextPriority2, TextRegularAlt, Spacing } from 'ui/components/atoms/typography/typography';
import { Row, Col } from 'ui/components/organisms/grid/grid';
import { ScrollButton } from 'ui/components/atoms/scroll_button/scroll_button';
import { Breadcrumbs, BreadcrumbsProps } from 'ui/components/molecules/breadcrumbs/breadcrumbs';
import styles from './banner_category.module.css';
import { WPImageFluid } from 'shared/types/wp_localImage';
import { Picture } from 'ui/components/atoms/picture/picture';
import { WatermarksBanner } from 'ui/components/atoms/watermarks/watermarksBanner';
import { scrollToElement } from 'util/scrollTo';
import { HtmlBreaks } from 'util/html';

export type BannerCategoryProps = {
  breadcrumbs: BreadcrumbsProps;
  heading?: string;
  description?: string;
  subheading?: string;
  image: null | WPImageFluid;
  scrollToRef: React.RefObject<HTMLDivElement>;
};

const createViewTreatmentsClick = (ref: React.RefObject<HTMLDivElement>) => () => {
  if (ref.current != null) {
    scrollToElement(ref.current);
  }
};

export const BannerCategory = ({
  image,
  description,
  subheading,
  heading,
  breadcrumbs,
  scrollToRef,
}: BannerCategoryProps) => {
  const viewTreatmentsClick = createViewTreatmentsClick(scrollToRef);
  return (
    <div className={styles.banner}>
      <div className={styles.bannerInner}>
        {image ? (
          <WatermarksBanner
            variant="P"
            aspectRatio={1 / image.localFile.childImageSharp.fluid.aspectRatio}
          />
        ) : null}

        <Container>
          <Row middle="md">
            <Col sm={12} md={6} lg={5} lgOffset={1}>
              <div className={styles.textContent}>
                <Breadcrumbs {...breadcrumbs} />
                <TextPriority2 tagName="h1" bottomSpacing={Spacing.SPACING_16}>
                  {heading}
                </TextPriority2>
                {description && (
                  <TextRegularAlt bottomSpacing={Spacing.SPACING_24}>
                    {HtmlBreaks(description)}
                  </TextRegularAlt>
                )}
                {subheading ? (
                  <ScrollButton
                    onClick={viewTreatmentsClick}
                    className={styles.scrollButton}
                    subheading={subheading}
                  />
                ) : null}
              </div>
            </Col>
            <Col className={styles.imageColumn} sm={12} md={6} lg={5}>
              <Picture
                fluid={image?.localFile.childImageSharp.fluid}
                type="fluid"
                placeholder="285x190"
                className={styles.image}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};
