import * as React from 'react';
import classNames from 'classnames';
import { AltLink } from 'ui/components/atoms/link/alt_link';
import { Spacing, TextPriority4, TextRegularAlt } from 'ui/components/atoms/typography/typography';
import { FeatureImage } from 'ui/components/molecules/feature/feature_image';
import { Container } from 'ui/components/organisms/container/container';
import { Col, Row } from 'ui/components/organisms/grid/grid';
import styles from './featured_treatment.module.css';
import { WPPageFeatureLink, WPPageLink } from 'shared/types/wp_link';
import { getFeaturedFixedImage } from 'util/getFeaturedImage';
import { exists } from 'util/exists';

type BackgroundColor = 'white' | 'pale';

type FeaturedTreatment = {
  heading: string;
  description: string;
  cta?: WPPageLink;
  items: {
    treatment: WPPageFeatureLink;
  }[];
  backgroundColor?: BackgroundColor;
};

export const FeaturedTreatment = ({
  heading,
  description,
  cta,
  items,
  backgroundColor = 'white',
}: FeaturedTreatment) => {
  return (
    <div className={styles.wrapper}>
      <Container>
        <div
          className={classNames(styles.background, {
            [styles.bgPale]: backgroundColor === 'pale',
          })}>
          <Row className={styles.contents}>
            <Col sm={12} lg={4} xl={3} className={styles.heading}>
              <TextPriority4 bottomSpacing={Spacing.SPACING_8}>{heading}</TextPriority4>
              <TextRegularAlt bottomSpacing={cta ? Spacing.SPACING_16 : Spacing.NONE}>
                {description}
              </TextRegularAlt>
              {cta && <AltLink href={cta.uri} label={cta.title} />}
            </Col>
            <Col sm={12} lg={8} xlOffset={1}>
              <Row>
                {items
                  .filter(x => exists(x.treatment))
                  .map((item, key) => (
                    <Col key={key} sm={12} lg={6}>
                      <FeatureImage
                        className={classNames(styles.feature, {
                          [styles.secondLastChild]: key === items.length - 2,
                          [styles.lastChild]: key === items.length - 1,
                        })}
                        background="pale"
                        key={key}
                        image={getFeaturedFixedImage(item.treatment.featuredImage?.node)}
                        href={item.treatment.uri}
                        heading={item.treatment.title}
                        description={item.treatment.excerpt}
                      />
                    </Col>
                  ))}
              </Row>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};
