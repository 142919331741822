import React, { useRef } from 'react';
import classNames from 'classnames';
import { PageTemplateProps } from 'shared/types/pages';
import { Divider } from 'ui/components/atoms/divider/divider';
import { Spacing, TextPriority3, TextSub2 } from 'ui/components/atoms/typography/typography';
import { BannerCategory } from 'ui/components/molecules/banner/banner_category';
import { Featured } from 'ui/components/molecules/featured/featured';
import { defaultHeading, GetInTouch } from 'ui/components/molecules/get_in_touch/get_in_touch';
import { Section } from 'ui/components/molecules/section/section';
import { Vimeo } from 'ui/components/molecules/vimeo/vimeo';
import { Container } from 'ui/components/organisms/container/container';
import { FeaturedTreatment } from 'ui/components/organisms/featured_treatment/featured_treatment';
import { Col, Row } from 'ui/components/organisms/grid/grid';
import styles from './treatment_category.module.css';
import { PageTreatmentCategoryProps } from 'templates/treatment_category';
import { WYSIWYG } from 'ui/components/molecules/wysiwyg/wysiwyg';
import { createRestrictedModal } from 'ui/components/molecules/modal/modal';
import { Main } from 'ui/components/organisms/main/main';
import { JsonLD } from 'ui/components/molecules/json_ld/json_ld';

export const TreatmentCategory = ({ data }: PageTemplateProps<PageTreatmentCategoryProps>) => {
  const {
    banner,
    enableContentGrid,
    contentGrid,
    treatments,
    introduction,
    enableVideo,
    featuredVideo,
    getInTouch,
    getInTouchOverride,
  } = data.wpPage.acfTemplateTreatmentCategory.treatmentCategory;
  const { breadcrumbs } = data.wpPage.seo;
  const ref = useRef<HTMLDivElement>(null);

  const { button: getInTouchButton } = getInTouch;
  const getInTouchProps =
    getInTouchOverride != null
      ? {
          preheading: getInTouch.preheading,
          heading: getInTouch.heading,
          description: getInTouch.description,
          ...(getInTouch.buttonOverride != null
            ? {
                primaryCta: {
                  label: getInTouchButton.label,
                  href: getInTouchButton.link,
                  target: getInTouchButton.target,
                },
              }
            : undefined),
        }
      : { heading: defaultHeading(data.wpPage.title) };

  const { RestrictedModal, store } = createRestrictedModal({
    acfSharedPage: data.wpPage.acfSharedPage,
  });

  return (
    <>
      <Main isBlurred={store.isVisible}>
        <JsonLD acfSharedPage={data.wpPage.acfSharedPage} breadcrumbs={breadcrumbs} />
        <BannerCategory
          heading={banner.heading}
          subheading={banner.subheading}
          description={banner.description}
          image={banner.image}
          breadcrumbs={{ breadcrumbs }}
          scrollToRef={ref}
        />

        {enableVideo && (
          <Section backgroundColor="navy" className={styles.videoSection}>
            <Container>
              <Row center="sm">
                <Col sm={12}>
                  <TextPriority3 tagName="h2" bottomSpacing={Spacing.SPACING_24} color="white">
                    {featuredVideo.title}
                  </TextPriority3>
                </Col>
              </Row>
              <Vimeo caption={featuredVideo.vimeo.caption} id={featuredVideo.vimeo.id} />
            </Container>
          </Section>
        )}

        <Section
          backgroundColor="grey"
          className={classNames(styles.centered, { [styles.hasVideo]: enableVideo })}>
          <Container>
            <WYSIWYG html={introduction.rte} />
          </Container>
        </Section>

        <div className={styles.treatments} ref={ref}>
          <Divider />
          <Container>
            <Row center="sm">
              <Col sm={12}>
                <TextSub2 tagName="div" bottomSpacing={Spacing.SPACING_8}>
                  {treatments.preheading}
                </TextSub2>
                <TextPriority3>{treatments.heading}</TextPriority3>
                <div className={styles.divider}></div>
              </Col>
            </Row>
          </Container>
          {treatments.featuredTreatments?.map((x, i) => (
            <FeaturedTreatment
              key={i}
              heading={x.heading}
              description={x.description}
              cta={x.cta}
              items={x.items ? x.items : []}
              backgroundColor={i % 2 === 0 ? 'white' : 'pale'}
            />
          ))}
        </div>

        {enableContentGrid && (
          <Featured
            heading={contentGrid.heading}
            subheading={contentGrid.preheading}
            items={
              contentGrid.items
                ? contentGrid.items.map(x => {
                    // Override image (optional). Otherwise, placeholder
                    return {
                      ...x,
                      link: x.link.uri,
                      image: x.imageOverride != null ? x.image : x.link.featuredImage?.node,
                    };
                  })
                : []
            }
            md={6}
          />
        )}

        <GetInTouch {...getInTouchProps} />
      </Main>
      <RestrictedModal />
    </>
  );
};
