import { graphql } from 'gatsby';
import { WPImageFluid } from 'shared/types/wp_localImage';
import { WPPageFeatureLink, WPPageLink } from 'shared/types/wp_link';
import { TreatmentCategory } from 'ui/components/templates/treatment_category/treatment_category';

export type PageTreatmentCategoryProps = {
  acfTemplateTreatmentCategory: {
    treatmentCategory: {
      banner: {
        heading: string;
        subheading: string;
        description: string;
        image: null | WPImageFluid;
      };
      enableVideo: boolean | null;
      featuredVideo: {
        title: string;
        vimeo: {
          id: string;
          caption: string;
        };
      };
      introduction: {
        rte: string;
      };
      enableContentGrid: boolean | null;
      contentGrid: {
        preheading: string;
        heading: string;
        items:
          | null
          | {
              description: string;
              heading: string;
              subheading: string;
              link: WPPageLink;
              imageOverride: boolean | null;
              image: null | WPImageFluid;
            }[];
      };
      treatments: {
        preheading: string;
        heading: string;
        featuredTreatments:
          | null
          | {
              heading: string;
              description: string;
              cta: WPPageLink;
              items:
                | null
                | {
                    treatment: WPPageFeatureLink;
                  }[];
            }[];
      };
      getInTouchOverride: boolean | null;
      getInTouch: {
        preheading: string;
        heading: string;
        description: string;
        buttonOverride: boolean | null;
        button: {
          label: string;
          link: string;
          target: '_blank' | '_self';
        };
      };
    };
  };
};

export const query = graphql`
  query CategoryPageQuery($id: String!) {
    wpPage(id: { eq: $id }) {
      ...PageContent
      acfTemplateTreatmentCategory {
        treatmentCategory {
          banner {
            heading
            description
            subheading
            image {
              localFile {
                ...FluidImageFull
              }
            }
          }
          enableVideo
          featuredVideo {
            title
            vimeo {
              id
              caption
            }
          }
          introduction {
            rte
          }
          enableContentGrid
          contentGrid {
            preheading
            heading
            items {
              description
              heading
              subheading
              link {
                ...PageLink
              }
              imageOverride
              image {
                localFile {
                  ...FluidImageFull
                }
              }
            }
          }
          treatments {
            preheading
            heading
            featuredTreatments {
              heading
              description
              cta {
                ... on WpPage {
                  href: uri
                  label: title
                }
              }
              items {
                treatment {
                  ...PageFeatureLink
                }
              }
            }
          }
          getInTouchOverride
          getInTouch {
            preheading
            heading
            description
            buttonOverride
            button {
              label
              link
              target
            }
          }
        }
      }
    }
    wpGlobal: wp {
      ...WpGlobal
    }
  }
`;

export default TreatmentCategory;
